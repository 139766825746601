import React, { useState } from 'react';
import { Box, Flex, TextV2 } from '@withjoy/joykit';
import { EventPageVisibility, GuestSiteTargetEnum, useGetDashboardPreviewPagesQuery } from '@graphql/generated';
import { constructPreviewUrl } from '@website-designer/components/WebsitePreview';
import { getTargetOrigin } from '@shared/utils/previewMessageBus';
import { getNodeEnv } from '@shared/utils/getEnvironmentVariables';
import { useWidthObserver } from '@apps/greetingCard/components/Premium/useWidthObserver';
import { IFRAME_WIDTH, PreviewContainer, styles } from './DashboardWebsitePreview.styles';
import { SketchedJoyLogo } from '@apps/admin/common/SketchedJoyLogo';
import { LockFilled, Moon, OpenInNewWindow, Sun } from '@withjoy/joykit/icons';
import { useRouterHelper, useTranslation } from '@shared/core';
import { animationTransition } from '@shared/utils/animationTransition';
import { boxShadowValuesV2 } from '@shared/utils/style/dropShadows';
import { SettingsRoutes } from '@apps/admin/routes/Settings/Settings.routes';

const { isProduction } = getNodeEnv();

const PageCount = ({ icon, label }: { icon: React.ReactNode; label: string }) => {
  return (
    <Flex paddingX={5} columnGap={2}>
      {icon}
      <TextV2 typographyVariant={'label2'}>{label}</TextV2>
    </Flex>
  );
};

export const DashboardWebsitePreview: React.FC<{ eventHandle: string }> = ({ eventHandle }) => {
  const targetOrigin = isProduction ? '' : getTargetOrigin();
  const previewUrl = constructPreviewUrl(targetOrigin, eventHandle, 'adminGuestSitePreview', GuestSiteTargetEnum.charm);
  const { t } = useTranslation('adminDashboardV2');
  const { publicPage, protectedPage, off } = t('websitePreview');
  const router = useRouterHelper();

  const { data, loading } = useGetDashboardPreviewPagesQuery({
    variables: {
      eventHandle
    },
    batchMode: 'fast'
  });

  const [iframeLoaded, setIframeLoaded] = useState(false);

  const [containerWidth, containerRef] = useWidthObserver();

  // Calculate the scaling factor based on the container's width
  const scale = containerWidth / IFRAME_WIDTH;

  if (loading) return null;

  const pages = {
    public: data?.eventByName?.pages.filter(page => page.visibility === EventPageVisibility.public).length,
    passwordProtected: data?.eventByName?.pages.filter(page => page.visibility === EventPageVisibility.passwordProtected).length,
    hidden: data?.eventByName?.pages.filter(page => page.visibility === EventPageVisibility.hidden).length
  };

  return (
    <Flex width={'100%'} rowGap={5} flexDirection={'column'} alignItems={'center'}>
      <PreviewContainer role="group" __css={styles.previewContainer(scale)} ref={containerRef} as={'a'} href={`/${eventHandle}`} target={'_blank'}>
        <Flex
          width={'100%'}
          height={'100%'}
          opacity={0}
          transition={animationTransition('opacity')}
          alignItems={'center'}
          justifyContent={'center'}
          position={'absolute'}
          zIndex={1}
          _groupHover={{
            opacity: 1
          }}
        >
          <Flex width={9} height={9} alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} backgroundColor={'white'} boxShadow={boxShadowValuesV2.md}>
            <OpenInNewWindow />
          </Flex>
        </Flex>
        <Box __css={styles.browserWindow(scale)}>
          <iframe
            // Setting key will guarantee a re-render when the url changes
            key={previewUrl}
            title={previewUrl}
            style={styles.iframe}
            sandbox={`allow-scripts allow-same-origin`}
            src={previewUrl}
            tabIndex={-1}
            onLoad={() => setIframeLoaded(true)}
          />
          <SketchedJoyLogo containerHeight="100%" shouldAnimate={!iframeLoaded} />
        </Box>
      </PreviewContainer>
      <Flex
        color={'mono12'}
        transition={animationTransition('color')}
        _hover={{
          color: 'mono8'
        }}
        paddingY={2}
        alignItems={'center'}
        as={'a'}
        href={router.buildPath(SettingsRoutes.website)}
      >
        <PageCount icon={<Sun size={'sm'} />} label={publicPage({ count: pages.public })} />
        <PageCount icon={<LockFilled size={'sm'} />} label={protectedPage({ count: pages.passwordProtected })} />
        <PageCount icon={<Moon size={'sm'} />} label={off({ count: pages.hidden })} />
      </Flex>
    </Flex>
  );
};
