import { animationTransition } from '@shared/joykit/packages/core/utils';
import { STANDARD_DURATION, STANDARD_TIMING_FUNCTION } from '@shared/utils/animationTransition';
import { boxShadowValuesV2 } from '@shared/utils/style/dropShadows';
import { Box, keyframes, styled, StyleSystemProps } from '@withjoy/joykit';

export const IFRAME_WIDTH = 1440;
export const IFRAME_HEIGHT = 960;

const growAndFade = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

export const PreviewContainer = styled(Box)`
  transform: scale(0);
  animation: ${growAndFade} ${STANDARD_DURATION} ${STANDARD_TIMING_FUNCTION} forwards;
  animation-delay: 1s;
  transform-origin: 50% 50%;
`;
const previewContainerStyle = (scale: number): StyleSystemProps => ({
  width: '100%', // Take up 100% of the parent width dynamically
  height: `${IFRAME_HEIGHT * scale}px`, // Proportional height based on aspect ratio
  overflow: 'hidden',
  position: 'relative',
  borderRadius: 3,
  boxShadow: boxShadowValuesV2.xxxl,
  backgroundColor: 'white',
  border: '1px solid',
  borderColor: 'mono3',
  transition: animationTransition('box-shadow'),
  _hover: {
    boxShadow: boxShadowValuesV2.hardxs
  }
});

const browserWindowStyles = (scale: number): StyleSystemProps => ({
  width: `${IFRAME_WIDTH}px`, // Original iframe width
  height: `${IFRAME_HEIGHT}px`, // Original iframe height
  transform: `scale(${scale})`, // Scale down based on container width
  transformOrigin: '0 0', // Scale from top-left
  paddingTop: 9,
  _after: {
    content: "' '",
    display: 'block',
    width: 5,
    height: 5,
    position: 'absolute',
    top: 6,
    borderRadius: '50%',
    transform: 'translateY(-50%)',
    left: 6,
    boxShadow: '24px 0 0 0 #EBEBEB, 48px 0 0 0 #EBEBEB',
    background: '#EBEBEB'
  }
});

const iframeStyle: React.CSSProperties = {
  width: `${IFRAME_WIDTH}px`, // Original iframe width
  height: `${IFRAME_HEIGHT - 48}px`, // Original iframe height - 48px for the browser window header
  border: 'none',
  pointerEvents: 'none'
};

export const styles = {
  previewContainer: previewContainerStyle,
  browserWindow: browserWindowStyles,
  iframe: iframeStyle
};
