import React, { useMemo, useEffect, useCallback, useRef } from 'react';
import { DashboardChecklistProvider, useDashboardChecklistContext } from './DashboardChecklist.provider';
import { Box, Flex, FlexProps, useDisclosure } from '@withjoy/joykit';
import { ChecklistProgressBar, ChecklistProgressBarHidableContent } from './components/ChecklistProgressBar';
import { differenceInCalendarDays } from 'date-fns';
import { ChecklistCardContainer, StyledExpandableChecklist, StyledParticleJoyLogo, styles } from './DashboardChecklist.styles';
import { ChecklistItem, ChecklistItemBaseProps, ChecklistItemWithDateProps } from './components/ChecklistItem';
import { ChecklistItemModal } from './components/ChecklistItemModal';
import { checklistSubItemsActions } from './DashboardChecklist.utils';
import { ChecklistItemActions, ChecklistSubItemActions } from './DashboardChecklist.types';
import {
  DashboardChecklistItemFragment,
  DashboardChecklistItemFragmentDoc,
  useCompleteChecklistItemMutation,
  useCompleteChecklistSubItemMutation,
  useReminderChecklistItemMutation
} from '@graphql/generated';
import { useDashboardChecklistTelemetry } from './DashboardChecklist.telemetry';
import { useInviteAdminDialogContext } from '@shared/context/InviteAdminDialog/InviteAdminDialog.provider';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { ChevronDown, Close } from '@withjoy/joykit/icons';
import { animationTransition } from '@shared/utils/animationTransition';
import { useIntercom } from '@shared/core/intercom';
import { withWindow } from '@shared/utils/withWindow';
import './DashboardChecklist.css';
import { useHasActiveModals } from '@withjoy/joykit/components/Modal/modalManager';
import { DashboardStats } from './components/DashboardStats';
import { boxShadowValuesV2 } from '@shared/utils/style/dropShadows';
import { DashboardWebsitePreview } from './components/DashboardWebsitePreview';

const CHECKLIST_ITEM_TYPE_NAME: DashboardChecklistItemFragment['__typename'] = 'ChecklistItem';

type ChecklistItemProps = {
  eventHandle: string;
  eventId: string;
  showCloseButton?: boolean;
};

const ExpandableChecklist: React.FC = ({ children }) => {
  return (
    <StyledExpandableChecklist>
      <Box overflow={'hidden'}>{children}</Box>
    </StyledExpandableChecklist>
  );
};

const ChecklistMainItem: React.FC<
  FlexProps &
    ChecklistItemWithDateProps & {
      action: ChecklistItemActions;
    }
> = ({ children, ...props }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const telemetry = useDashboardChecklistTelemetry();

  const handleOnOpen = useCallback(() => {
    telemetry.openChecklistItem(props.action);
    onOpen();
  }, [telemetry, props.action, onOpen]);

  return (
    <>
      <ChecklistItem {...props} onClick={handleOnOpen} />
      <ChecklistItemModal
        isOpen={isOpen}
        onClose={onClose}
        id={props.id}
        title={props.title}
        action={props.action}
        reminderAt={props.reminderAt}
        onChangeReminderDate={props.onChangeReminderDate}
      >
        {children}
      </ChecklistItemModal>
    </>
  );
};

const DashboardChecklistInner: React.FC<Pick<ChecklistItemProps, 'eventHandle'>> = ({ eventHandle, children }) => {
  const { isChecklistExpanded, toggleChecklist, checklistData, checklistDataLoading, checklistItemsCompleted, checklistItemsTotal } = useDashboardChecklistContext();
  const telemetry = useDashboardChecklistTelemetry();
  const { openInviteAdminDialog } = useInviteAdminDialogContext();
  const intercom = useIntercom();

  const [completeChecklistItem] = useCompleteChecklistItemMutation();
  const [remindChecklistItem] = useReminderChecklistItemMutation();
  const [completeChecklistSubItem] = useCompleteChecklistSubItemMutation();

  const handleCompleteChecklistItem: ChecklistItemBaseProps['onComplete'] = useCallback(
    (id, completedAt, parentId, action) => {
      telemetry.completeChecklistItem(action as ChecklistItemActions, completedAt);
      completeChecklistItem({
        variables: {
          id,
          input: {
            completedAt
          }
        },
        optimisticResponse: {
          updateChecklistItem: {
            __typename: 'ChecklistItem',
            id,
            completedAt
          }
        }
      });
    },
    [completeChecklistItem, telemetry]
  );

  const handleRemindChecklistItem = useCallback(
    (id: string, date: string, action: ChecklistItemActions, initiatedFrom: 'list' | 'modal' = 'list') => {
      const reminderAt = date.length === 0 ? null : new Date(date).toISOString();

      telemetry.changeReminderDate(action, date, initiatedFrom);
      remindChecklistItem({
        variables: {
          id,
          input: {
            reminderAt
          }
        },
        optimisticResponse: {
          updateChecklistItem: {
            __typename: 'ChecklistItem',
            id,
            reminderAt
          }
        }
      });
    },
    [remindChecklistItem, telemetry]
  );

  const handleCompleteChecklistSubItem: ChecklistItemBaseProps['onComplete'] = useCallback(
    (id, completedAt, parentId, action) => {
      telemetry.completeChecklistSubItem(action as ChecklistSubItemActions, completedAt);
      completeChecklistSubItem({
        variables: {
          id,
          input: {
            completedAt
          }
        },
        optimisticResponse: {
          updateChecklistSubItem: {
            __typename: 'ChecklistSubItem',
            id,
            completedAt
          }
        },
        update: cache => {
          // We need to mark the parent item as completed if all subItems are completed
          const cacheIdentifiedChecklistItem = cache.identify({ __typename: CHECKLIST_ITEM_TYPE_NAME, id: parentId });

          if (!cacheIdentifiedChecklistItem) return;

          const cacheItem = cache.readFragment<DashboardChecklistItemFragment>({
            id: cacheIdentifiedChecklistItem,
            fragment: DashboardChecklistItemFragmentDoc
          });

          if (!cacheItem) return;

          const allSubItemsCompleted = cacheItem.subItems.every(subItem => subItem.completedAt !== null);

          cache.writeFragment({
            id: cacheIdentifiedChecklistItem,
            fragment: DashboardChecklistItemFragmentDoc,
            data: {
              ...cacheItem,
              completedAt: allSubItemsCompleted ? new Date().toISOString() : null
            }
          });
        }
      });
    },
    [completeChecklistSubItem, telemetry]
  );

  const handleToggleChecklist = useCallback(() => {
    telemetry.toggleChecklist(isChecklistExpanded);
    toggleChecklist();
  }, [isChecklistExpanded, telemetry, toggleChecklist]);

  const calculateProgress = useMemo(() => {
    return (checklistItemsCompleted / checklistItemsTotal) * 100;
  }, [checklistItemsCompleted, checklistItemsTotal]);

  const daysUntilEvent = useMemo(() => {
    if (!checklistData?.eventByName?.info.finalizedDate) return null;

    const today = Date.now();
    return differenceInCalendarDays(checklistData?.eventByName?.info.finalizedDate?.milliseconds, today);
  }, [checklistData]);

  const handleOnChecklistItemClick = useCallback(
    (e: React.MouseEvent, action: ChecklistSubItemActions, link: string) => {
      telemetry.clickChecklistSubItem(action);

      if (link.length) return;

      switch (action) {
        case 'invitePartner':
          openInviteAdminDialog();
          return;
        case 'chatWithConcierge':
          intercom.show();
          return;
      }
    },
    [intercom, openInviteAdminDialog, telemetry]
  );

  return (
    <>
      <ChecklistProgressBar
        isExpanded={isChecklistExpanded}
        toggle={handleToggleChecklist}
        progressPercentage={calculateProgress}
        daysUntilEvent={daysUntilEvent}
        showSkeleton={checklistDataLoading}
      />
      <Box className={isChecklistExpanded ? 'expanded' : 'collapsed'}>
        <ExpandableChecklist>
          <ChecklistProgressBarHidableContent completedItems={checklistItemsCompleted} daysUntilEvent={daysUntilEvent} />
          {checklistData?.eventByName?.checklist?.items && (
            <Box borderBottom={'1px solid'} borderBottomColor={'mono3'} boxShadow={boxShadowValuesV2.xxxl}>
              {checklistData?.eventByName?.checklist?.items.map(item => (
                <ChecklistMainItem
                  key={item.id}
                  id={item.id}
                  title={item.title}
                  subtitle={item.subtitle}
                  action={item.action as ChecklistItemActions}
                  completed={item.completedAt !== null}
                  withDate
                  reminderAt={item.reminderAt}
                  onChangeReminderDate={handleRemindChecklistItem}
                  onComplete={handleCompleteChecklistItem}
                >
                  {item.subItems.map(subItem => {
                    const action = subItem.action as ChecklistSubItemActions;
                    return (
                      <ChecklistItem
                        key={subItem.id}
                        parentId={item.id}
                        id={subItem.id}
                        action={action as ChecklistSubItemActions}
                        title={subItem.title}
                        icon={checklistSubItemsActions[action].icon}
                        onClick={e => handleOnChecklistItemClick(e, action, checklistSubItemsActions[action].link)}
                        completed={subItem.completedAt !== null}
                        onComplete={handleCompleteChecklistSubItem}
                        {...(checklistSubItemsActions[action].link.length && {
                          as: 'a',
                          href:
                            checklistSubItemsActions[action].link.slice(0, 1) === '/'
                              ? `/${eventHandle}${checklistSubItemsActions[action].link}`
                              : checklistSubItemsActions[action].link,
                          target: checklistSubItemsActions[action].link.slice(0, 1) === '/' ? '_self' : '_blank'
                        })}
                      />
                    );
                  })}
                </ChecklistMainItem>
              ))}
            </Box>
          )}
          {children}
        </ExpandableChecklist>
      </Box>
    </>
  );
};

const DashboardChecklistMobile: React.FC<ChecklistItemProps> = ({ eventHandle, showCloseButton }) => {
  const { toggleChecklist, isChecklistExpanded, adminDashboardStatsEnabled } = useDashboardChecklistContext();
  const intercom = useIntercom();
  const telemetry = useDashboardChecklistTelemetry();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const hasActiveModals = useHasActiveModals();

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (containerRef && containerRef.current) {
        const el = e.target;
        const domNode = containerRef.current;

        if (
          !(domNode && el instanceof Node && domNode.contains(el)) &&
          // Only close checklist if it's open and there are no active modals
          isChecklistExpanded &&
          !hasActiveModals
        ) {
          toggleChecklist();
        }
      }
    };

    withWindow(window => {
      window.document.addEventListener('mousedown', handleClickOutside);
    });

    return () => {
      withWindow(window => {
        window.document.removeEventListener('mousedown', handleClickOutside);
      });
    };
  }, [containerRef, hasActiveModals, isChecklistExpanded, toggleChecklist]);

  const bodyTag = useMemo(() => {
    return withWindow(global => {
      return global.document.getElementsByTagName('body')[0];
    }, null);
  }, []);

  useEffect(() => {
    if (!bodyTag) {
      return;
    }

    return () => {
      bodyTag.classList.remove('bottom-checklist-open');
    };
  }, [bodyTag]);

  const handleToggleChecklist = useCallback(() => {
    if (isChecklistExpanded) {
      intercom.showLauncher();
      bodyTag?.classList.remove('bottom-checklist-open');
    } else {
      intercom.hideLauncher();
      bodyTag?.classList.add('bottom-checklist-open');
    }
    telemetry.toggleChecklist(isChecklistExpanded);
    toggleChecklist();
  }, [bodyTag?.classList, intercom, isChecklistExpanded, telemetry, toggleChecklist]);

  return (
    <>
      {isChecklistExpanded && (
        <Flex
          opacity={showCloseButton ? 1 : 0}
          transition={animationTransition('opacity')}
          position={'fixed'}
          height={8}
          width={8}
          border={'1px solid'}
          borderColor={'mono5'}
          borderRadius={5}
          top={5}
          right={5}
          onClick={handleToggleChecklist}
          zIndex={10}
          cursor={'pointer'}
          alignItems={'center'}
          justifyContent={'center'}
          background={'white'}
        >
          <Close />
        </Flex>
      )}
      <Box paddingTop={4} backgroundColor={isChecklistExpanded ? 'mono2' : 'white'} transition={animationTransition('background-color')} ref={containerRef}>
        <Flex alignItems={'center'} justifyContent={'center'} onClick={handleToggleChecklist}>
          <ChevronDown transform={isChecklistExpanded ? 'rotate(0)' : 'rotate(180deg)'} transition={animationTransition('transform')} />
        </Flex>
        <DashboardChecklistInner eventHandle={eventHandle}>
          {isChecklistExpanded && (
            <>
              <Box paddingX={5} paddingTop={7}>
                <DashboardWebsitePreview eventHandle={eventHandle} />
              </Box>
              {adminDashboardStatsEnabled && (
                <Flex paddingX={5} paddingY={7} rowGap={7} flexDirection={'column'} alignItems={'center'}>
                  <DashboardStats eventHandle={eventHandle} />
                  <StyledParticleJoyLogo id="footer2brand-desktop" logoColor={'white'} />
                </Flex>
              )}
            </>
          )}
        </DashboardChecklistInner>
      </Box>
    </>
  );
};

const DashboardChecklistDesktop: React.FC<ChecklistItemProps> = ({ eventHandle }) => {
  const { isChecklistExpanded, adminDashboardStatsEnabled } = useDashboardChecklistContext();

  return (
    <>
      <DashboardWebsitePreview eventHandle={eventHandle} />
      <ChecklistCardContainer className={isChecklistExpanded ? 'expanded' : 'collapsed'} paddingBottom={isChecklistExpanded ? 0 : 7} __css={styles.checklistCardContainer}>
        <DashboardChecklistInner eventHandle={eventHandle} />
      </ChecklistCardContainer>
      {adminDashboardStatsEnabled && <DashboardStats eventHandle={eventHandle} />}
      <StyledParticleJoyLogo id="footer2brand-desktop" logoColor={'white'} />
    </>
  );
};

export const DashboardChecklist: React.FC<ChecklistItemProps> = props => {
  const isMobile = useIsMobileScreen();

  return (
    <DashboardChecklistProvider eventHandle={props.eventHandle} eventId={props.eventId}>
      {isMobile ? <DashboardChecklistMobile {...props} /> : <DashboardChecklistDesktop {...props} />}
    </DashboardChecklistProvider>
  );
};
