import React, { useCallback, useMemo } from 'react';
import { Colors } from '@withjoy/joykit/theme';
import { CashFunds, CheckOutline, Clock, CloseOutline, Gift, GuestList, HotelProduct, MailingAddress, Send, Shipping, Star } from '@withjoy/joykit/icons';
import { useTranslation } from '@shared/core';
import { DashboardStat } from './DashboardStat';
import { EventPageVisibility, StationeryTemplateCategoryEnum } from '@graphql/generated';
import { HOTEL_SELECTION_LOCAL_STORAGE_KEY } from '@apps/admin/routes/Planning/routes/HotelSelection/context/HotelSelectionCartContext/HotelSelectionCart.const';
import { useFeatureValue } from '@shared/core/featureFlags';
import { EventPageSlugs } from '@apps/admin/routes/Dashboard/common/enums/EventPageSlugs';
import { useIntercom } from '@shared/core/intercom';
import { useDashboardChecklistTelemetry } from '../../DashboardChecklist.telemetry';
import { useDashboardChecklistContext } from '../../DashboardChecklist.provider';

export type Stat = {
  id:
    | 'potentialGuests'
    | 'contactsCollected'
    | 'printsOrdered'
    | 'digitalCardsSent'
    | 'hotelWishList'
    | 'roomsRequested'
    | 'giftsAvailable'
    | 'reservedAndPurchased'
    | 'thankYouNotesSent'
    | 'cashReceived'
    | 'attending'
    | 'declined'
    | 'pending';
  value: number;
  total?: number;
  type: 'default' | 'progress';
  link?: string;
  icon: React.ReactNode;
};

export type StatCard = {
  id: 'guestList' | 'saveTheDates' | 'hotels' | 'registry' | 'rsvp' | 'invitations';
  color: keyof Colors;
  extraInfo?: EventPageVisibility;
  stats: Stat[][];
};

export const DashboardStats: React.FC<{
  eventHandle: string;
}> = ({ eventHandle }) => {
  const { t2 } = useTranslation('adminDashboardV2');
  const translations = t2('stats');
  const isHotelBlockEnabled = useFeatureValue('hotelBlockEnabled').value === 'on';
  const intercom = useIntercom();
  const telemetry = useDashboardChecklistTelemetry();
  const { statsDataLoading, statsData } = useDashboardChecklistContext();

  const statCards = useMemo<StatCard[] | null>(() => {
    if (!statsData) {
      return null;
    }

    const printSaveTheDates = statsData.stationeryPrintOrdersByEvent.orders
      .filter(order => order.cardDraft?.stationeryTemplate.category === StationeryTemplateCategoryEnum.saveTheDate)
      .reduce((acc, order) => acc + order.quantity, 0);
    const printInvitations = statsData.stationeryPrintOrdersByEvent.orders
      .filter(order => order.cardDraft === null || order.cardDraft?.stationeryTemplate.category === StationeryTemplateCategoryEnum.invitation)
      .reduce((acc, order) => acc + order.quantity, 0);
    const cashReceivedInMinorUnits =
      statsData.eventByName?.registry.items.reduce(
        (acc, item) => acc + (item.donationFund?.donations ? item.donationFund?.donations.reduce((acc, donation) => acc + donation.amount.valueInMinorUnits, 0) : 0) || 0,
        0
      ) || 0;
    const goalInMinorUnits = statsData.eventByName?.registry.items.reduce((acc, item) => acc + (item.donationFund?.goalMonetaryValue?.valueInMinorUnits || 0), 0);
    const rsvpStats = statsData.eventByName?.guests.people.reduce(
      (acc, guest) => {
        const rsvpQuestion = guest.legacyRsvpAnswers?.find(answer => answer?.questionId === 'rsvp');
        if (rsvpQuestion?.answer === 'true') {
          acc.attending += 1;
        }
        if (rsvpQuestion?.answer === 'false') {
          acc.declined += 1;
        }
        if (rsvpQuestion?.answer === null) {
          acc.pending += 1;
        }
        return acc;
      },
      {
        attending: 0,
        declined: 0,
        pending: 0
      }
    );

    const cards: StatCard[] = [];

    if (!statsData.eventByName?.productVerticals?.optOutOfGuestList) {
      cards.push({
        id: 'guestList',
        color: 'accentBlue3',
        stats: [
          [
            { id: 'potentialGuests', value: statsData.eventByName?.guests.peopleCount || 0, type: 'default', icon: <GuestList />, link: '/edit/guests' },
            {
              id: 'contactsCollected',
              value: statsData.getContactCollectionByEventId?.contacts?.length || 0,
              type: 'default',
              icon: <MailingAddress />,
              link: '/edit/contact'
            }
          ]
        ]
      });
    }

    if (!statsData.eventByName?.productVerticals?.optOutOfInvites) {
      cards.push({
        id: 'saveTheDates',
        color: 'accentGreen3',
        stats: [
          [
            { id: 'printsOrdered', value: printSaveTheDates, type: 'default', icon: <Shipping />, link: '/edit/cards?ctgy=saveTheDate' },
            { id: 'digitalCardsSent', value: statsData.saveTheDatesECards.length, type: 'default', icon: <Send />, link: '/edit/ecard/savethedate' }
          ]
        ]
      });
    }

    if (isHotelBlockEnabled) {
      cards.push({
        id: 'hotels',
        color: 'linkText',
        stats: [
          [
            {
              id: 'hotelWishList',
              value: JSON.parse(localStorage.getItem(HOTEL_SELECTION_LOCAL_STORAGE_KEY(statsData?.eventByName?.id || '')) ?? '[]').length ?? 0,
              type: 'default',
              icon: <Star />,
              link: '/edit/planning/hotelblock'
            },
            { id: 'roomsRequested', value: +(statsData.eventByName?.hotelRoomBlocks?.numberOfRooms ?? 0), type: 'default', icon: <HotelProduct /> }
          ]
        ]
      });
    }

    if (!statsData.eventByName?.productVerticals?.optOutOfRegistry) {
      cards.push({
        id: 'registry',
        color: 'accentBlue5',
        extraInfo: statsData.eventByName?.pages?.find(page => page.pageSlug === EventPageSlugs.Registry)?.visibility,
        stats: [
          [
            {
              id: 'giftsAvailable',
              value: statsData.eventByName?.registry.itemCount.available || 0,
              type: 'default',
              icon: <Gift />,
              link: '/edit/registry/manage?filter=stillNeeded'
            },
            {
              id: 'reservedAndPurchased',
              value: statsData.eventByName?.registry.itemCount.purchasedOrReserved || 0,
              type: 'default',
              icon: <CheckOutline />,
              link: '/edit/registry/manage?filter=reservedAndPurchased'
            },
            { id: 'thankYouNotesSent', value: statsData.getMessageRelationships.length || 0, type: 'default', icon: <Send />, link: '/edit/registry/track' }
          ],
          [
            {
              id: 'cashReceived',
              value: cashReceivedInMinorUnits,
              total: goalInMinorUnits,
              type: 'progress',
              icon: <CashFunds />,
              link: '/edit/registry/track'
            }
          ]
        ]
      });
    }

    if (!statsData.eventByName?.productVerticals?.optOutOfGuestList) {
      cards.push({
        id: 'rsvp',
        color: 'accentBlue4',
        extraInfo: statsData.eventByName?.pages?.find(page => page.pageSlug === EventPageSlugs.Rsvp)?.visibility,
        stats: [
          [
            { id: 'attending', value: rsvpStats?.attending || 0, type: 'default', icon: <CheckOutline />, link: '/edit/rsvp' },
            { id: 'declined', value: rsvpStats?.declined || 0, type: 'default', icon: <CloseOutline />, link: '/edit/rsvp' },
            { id: 'pending', value: rsvpStats?.pending || 0, type: 'default', icon: <Clock />, link: '/edit/rsvp' }
          ]
        ]
      });
    }

    if (!statsData.eventByName?.productVerticals?.optOutOfInvites) {
      cards.push({
        id: 'invitations',
        color: 'accentGreen3',
        stats: [
          [
            { id: 'printsOrdered', value: printInvitations, type: 'default', icon: <Shipping />, link: '/edit/cards?ctgy=invitation' },
            { id: 'digitalCardsSent', value: statsData.invitationsECards.length, type: 'default', icon: <Send />, link: '/edit/ecard/invitation' }
          ]
        ]
      });
    }

    return cards.length ? cards : null;
  }, [isHotelBlockEnabled, statsData]);

  const handleStatClick = useCallback(
    (statId: Stat['id'], statCardId: StatCard['id']) => {
      telemetry.statItemClicked(statId, statCardId);

      if (statId === 'roomsRequested' && intercom) {
        intercom.show();
      }
    },
    [intercom, telemetry]
  );

  if (statsDataLoading || !statCards) {
    return (
      <>
        <DashboardStat.Skeleton />
        <DashboardStat.Skeleton />
        <DashboardStat.Skeleton />
      </>
    );
  }

  return (
    <>
      {statCards.map(card => (
        <DashboardStat key={card.id}>
          <DashboardStat.Header title={translations[card.id].title} extraInfo={card.extraInfo} />
          {card.stats.map((stats, index) => (
            <DashboardStat.Row key={'statrow' + index}>
              {stats.map(stat =>
                stat.type === 'progress' ? (
                  <DashboardStat.ItemProgress
                    key={stat.id}
                    value={stat.value}
                    total={stat.total || 0}
                    color={card.color}
                    icon={stat.icon}
                    cta={translations[stat.id].cta}
                    ctaHover={translations[stat.id].ctaHover}
                    goalLabel={translations.progressType.goal}
                    currencyCode={statsData?.eventByName?.registry.currency?.code}
                    currencyPlaces={statsData?.eventByName?.registry.currency?.places}
                    onClick={e => handleStatClick(stat.id, card.id)}
                    {...(stat.link && {
                      link: `/${eventHandle}${stat.link}`
                    })}
                  />
                ) : (
                  <DashboardStat.ItemDefault
                    key={stat.id}
                    value={stat.value}
                    color={card.color}
                    icon={stat.icon}
                    cta={translations[stat.id].cta}
                    ctaHover={translations[stat.id].ctaHover}
                    onClick={e => handleStatClick(stat.id, card.id)}
                    {...(stat.link && {
                      link: `/${eventHandle}${stat.link}`
                    })}
                  />
                )
              )}
            </DashboardStat.Row>
          ))}
        </DashboardStat>
      ))}
    </>
  );
};
